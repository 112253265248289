<template>
  <div>
    <div class="my-5 p-5 text-center" v-if="bookingDetails.type === 'C'">
      <img src="../assets/images/reservation-success.png" alt="" />
      <h5>{{ $t('listing.bookingRequestSent') }}</h5>
      <div class="my-3 small">
        A confirmation mail has been sent to your email address: {{ bookingDetails.booker }}
      </div>
      <div class="mt-3">
        <a :href="`/listing/${bookingDetails.propertyId}`" class="btn btn-outline-main">{{ $t('listing.backToListing') }}</a>
      </div>
    </div>
    <div class="my-5 p-5 text-center" v-else>
      <img src="../assets/images/reservation-success.png" alt="" />
      <h5>Your booking has been sent successfully</h5>
      <div class="my-3 small">
        A confirmation mail has been sent to your email address: {{ bookingDetails.booker }}
      </div>
      <div class="my-4">Booking ID: {{ bookingDetails.reservationId }}</div>
      <div class="mt-3 mb-2">
        <div>Total Price: {{ bookingDetails.currency }} {{ bookingDetails.total }} <span class="small">(VAT included)</span></div>
      </div>
      <div class="mt-3">
        <a :href="`/listing/${bookingDetails.propertyId}`" class="btn btn-outline-main">{{ $t('listing.backToListing') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReservationSuccess",
  data() {
    return {
      bookingDetails: {},
    }
  },
  mounted() {
    this.bookingDetails = this.$route.query;
  }
};
</script>
