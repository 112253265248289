<template>
  <ReservationSuccess></ReservationSuccess>
</template>
<script>
import ReservationSuccess from "../components/ReservationSuccess.vue";
export default {
  name: "HotelReservationSuccess",
  components: {
    ReservationSuccess,
  },
};
</script>
